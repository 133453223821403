import { TableBody } from "@material-ui/core";
import { displayGender } from "../../lib/gender";
import { InformationRow } from "./informationRow";

//詳細画面の共通IDとテレマのテーブル（個人・法人情報のテーブル）の値を設定する
//todo:libraとテレマの構造が違う(idも違う)のでマッピングが面倒、もう少しスマートに書きたい
export function InformationBody(props: any) {
  const libra = props.libra;
  const telema = props.telema;

  return (
    <TableBody style={{ wordBreak: "break-all" }}>
      <InformationRow
        labelName="個人・法人"
        libra={libra.user_type}
        telema={telema.user_type}
      />
      <InformationRow
        labelName="ご契約者名"
        libra={
          libra.user_type === "個人" ? libra.formatted : libra.corporation_name
        }
        telema={
          telema.user_type === "個人"
            ? telema.formatted
            : telema.corporation_name
        }
      />
      <InformationRow
        labelName="ご契約者名カナ"
        libra={
          libra.user_type === "個人"
            ? libra.formatted_kana
            : libra.corporation_name_kana
        }
        telema={
          telema.user_type === "個人"
            ? telema.formatted_kana
            : telema.corporation_kana
        }
      />
      {(libra.user_type === "個人" || telema.user_type === "個人") && (
        <InformationRow
          labelName="生年月日"
          libra={libra.birth_date}
          telema={telema.birthday}
        />
      )}
      {(libra.user_type === "個人" || telema.user_type === "個人") && (
        <InformationRow
          labelName="性別"
          libra={displayGender(libra.gender)}
          telema={telema.sex_division}
        />
      )}
      <InformationRow
        labelName="郵便番号"
        libra={libra.postal_code}
        telema={telema.post_code}
      />
      <InformationRow
        labelName="住所"
        libra={libra.address}
        telema={telema.address}
      />
      <InformationRow
        labelName="認証用携帯電話番号"
        libra={libra.auth_phone_number}
        telema="-"
      />
      <InformationRow
        labelName="携帯電話番号"
        libra={libra.cell_phone_number}
        telema="-"
      />
      <InformationRow
        labelName="連絡用電話番号"
        libra={libra.phone_number}
        telema="-"
      />
      <InformationRow
        labelName="会員電話番号"
        libra="-"
        telema={telema.member_phone_number}
      />
      <InformationRow
        labelName="電話番号1"
        libra="-"
        telema={telema.phone_number1}
      />
      <InformationRow
        labelName="電話番号2"
        libra="-"
        telema={telema.phone_number2}
      />
      <InformationRow
        labelName="電話番号3"
        libra="-"
        telema={telema.phone_number3}
      />
      <InformationRow
        labelName="メールアドレス"
        libra={libra.mail_address}
        telema="-"
      />
      <InformationRow
        labelName="メールアドレス1"
        libra="-"
        telema={telema.email_address1}
      />
      <InformationRow
        labelName="メールアドレス2"
        libra="-"
        telema={telema.email_address2}
      />
      <InformationRow
        labelName="メールアドレス3"
        libra="-"
        telema={telema.email_address3}
      />
      {(libra.user_type === "法人" || telema.user_type === "法人") && (
        <InformationRow
          labelName="部署名"
          libra={libra.division}
          telema={telema.mgmt_department}
        />
      )}
      {(libra.user_type === "法人" || telema.user_type === "法人") && (
        <InformationRow
          labelName="部署名カナ"
          libra={libra.division_kana}
          telema="-"
        />
      )}
      {(libra.user_type === "法人" || telema.user_type === "法人") && (
        <InformationRow
          labelName="担当者名"
          libra={libra.contact_person_name}
          telema={telema.corporation_person_name}
        />
      )}
      {(libra.user_type === "法人" || telema.user_type === "法人") && (
        <InformationRow
          labelName="担当者名カナ"
          libra={libra.contact_person_kana}
          telema={telema.corporation_person_kana}
        />
      )}
      <InformationRow
        labelName="テレマID"
        libra="-"
        telema={telema.applicationId}
      />
      <InformationRow
        labelName="オーナーズカードID"
        libra="-"
        telema={telema.locId}
      />
      <InformationRow
        labelName="内部会員ID"
        libra="-"
        telema={telema.internal_member_id}
      />
      <InformationRow labelName="SCIMID" libra={libra.scim_id} telema="-" />
      <InformationRow
        labelName="最終更新日"
        libra={libra.last_modified}
        telema={telema.update_time}
      />
    </TableBody>
  );
}
